<template>
  <div class="app_con">
    <div class="p-20">
      <div class="m-b-20">
        <el-row>
          <el-col :span="6">
            <el-button type="success" @click="dialogVisible = true"
              >新增</el-button
            >
            <el-button
              type="primary"
              @click="handelEdit"
              :disabled="selecTionArr.length !== 1"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="handelDel"
              :disabled="selecTionArr.length <= 0"
              >删除</el-button
            >
            <el-button
              type="warning"
              @click="openCard"
              :disabled="selecTionArr.length <= 0"
              >开卡</el-button
            >
          </el-col>
          <el-col :span="18">
            <div class="search">
              <el-input
                placeholder="根据姓名查询"
                v-model="filtterData.name"
                class="searchInput"
              >
                <template slot="prepend">姓名</template>
              </el-input>
              <el-input
                placeholder="根据身份查询"
                v-model="filtterData.personIdentityId"
                class="searchInput"
              >
                <template slot="prepend">身份</template>
              </el-input>
              <el-input
                placeholder="根据卡号查询"
                v-model="filtterData.cardNumber"
                class="searchInput"
              >
                <template slot="prepend">卡号</template>
              </el-input>
              <el-input
                placeholder="根据人员编号查询"
                v-model="filtterData.code"
                class="searchInput"
              >
                <template slot="prepend">编号</template>
              </el-input>
              <div class="searchInput el-input-group">
                <div class="el-input-group__prepend">部门</div>
                <el-select
                  filterable
                  v-model="filtterData.deptIdsString"
                  clearable
                  placeholder="根据部门筛选"
                >
                  <el-option
                    v-for="item in departmentList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="searchInput el-input-group">
                <div class="el-input-group__prepend">状态</div>
                <el-select
                  filterable
                  v-model="filtterData.status"
                  clearable
                  placeholder="根据状态筛选"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div> -->
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table
        :data="personnelList"
        border
        style="width: 100%"
        v-loading="loading"
        row-key="id"
        @selection-change="getSelection($event)"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <template v-for="v in props">
          <el-table-column
            :key="v.id"
            :prop="v.prop"
            :label="v.label"
            :width="v.width ? v.width : ''"
          >
            <template slot-scope="scope">
              <span
                class="status-radius"
                :class="setColorClass(scope.row.deviceStatus)"
                v-if="v.type && v.type == 'status'"
              >
                {{
                  statusManage.deviceStatus(scope.row.deviceStatus).name
                }}</span
              >

              <span v-else>{{ scope.row[v.prop] }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <el-pagination
        background
        :current-page.sync="searchQuery.pageNum"
        :page-size="searchQuery.pageSize"
        :total="searchQuery.total"
        :layout="'sizes, prev, pager, next, jumper'"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>
    <el-dialog
      title="添加人员"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="dialogVisible = false"
    >
      <div class="p-l-r-30">
        <el-form
          :rules="rules"
          :model="form"
          ref="form"
          inline
          label-width="80px"
          class="inlineForm"
        >
          <el-form-item label="姓名" prop="name">
            <el-input
              clearable
              class="inlineFormInput"
              v-model="form.name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话" >
            <el-input
              clearable
              class="inlineFormInput"
              v-model="form.phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="证件类型" prop="paperType">
            <el-select
              class="inlineFormInput"
              filterable
              v-model="form.paperType"
              clearable
              placeholder="选择证件类型"
            >
              <el-option
                v-for="item in paperTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码" prop="paperNumber">
            <el-input
              clearable
              class="inlineFormInput"
              v-model="form.paperNumber"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="人员编号" prop="code">
            <el-input
              clearable
              class="inlineFormInput"
              v-model="form.code"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item label="部门" prop="deptId">
            <el-select
              class="inlineFormInput"
              filterable
              v-model="form.deptId"
              clearable
              placeholder="选择部门"
            >
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select
              class="inlineFormInput"
              filterable
              v-model="form.sex"
              clearable
              placeholder="选择性别"
            >
              <el-option
                v-for="item in [
                  { value: '男', label: '男' },
                  { value: '女', label: '女' },
                ]"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <!--  -->
            <el-select
              class="inlineFormInput"
              filterable
              v-model="form.status"
              clearable
              placeholder="选择性别"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="照片" prop="photo">
            <el-upload
              class="avatar-uploader"
              action=""
              :on-change="getFile"
              list-type="picture-card"
              :auto-upload="false"
              :on-remove="onRemove"
              :limit="1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button @click="save" type="primary">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="开卡"
      :visible.sync="dialogFaceVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="dialogVisible = false"
    >
      <el-form ref="form" inline label-width="80px" class="inlineForm">
        <el-form-item label="通道" prop="paperType">
          <el-select
            class="inlineFormInput"
            filterable
            v-model="faceAuth.cardPrivilegeDetails[0].resouceCode"
            clearable
            multiple
            @change="selectMultiple"
            placeholder="选择通道"
          >
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.deviceName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开门计划" prop="paperType">
          <el-select
            class="inlineFormInput"
            filterable
            v-model="faceAuth.timeQuantumId"
            clearable
            placeholder="选择开门计划"
          >
            <el-option
              v-for="item in openPlanList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFaceVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFaceVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="开卡"
      top="10vh"
      :visible.sync="dialogOpenCardVisible"
      width="1100px"
      :close-on-click-modal="false"
      @close="dialogOpenCardVisible = false"
    >
      <div class="p-l-r-10 form-box">
        <el-form
          ref="openCardForm"
          inline
          label-width="80px"
          class="inlineForm"
        >
          <div
            class="form-item-box p-t-20 p-b-10 p-l-30"
            :key="v.personId"
            v-for="(v, i) in openCardForm"
          >
            <div>
              <el-form-item label="姓名" prop="personName">
                <el-input
                  readonly
                  clearable
                  class="inlineFormInput"
                  v-model="v.personName"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="卡号" prop="cardNumber">
                <el-input
                  clearable
                  class="inlineFormInput"
                  v-model="v.cardNumber"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="卡片类型" prop="cardType">
                <el-select
                  class="inlineFormInput"
                  filterable
                  v-model="v.cardType"
                  clearable
                  placeholder="选择卡片类型"
                >
                  <el-option
                    v-for="item in cardTypeList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="卡类别" prop="category">
                <el-select
                  class="inlineFormInput"
                  filterable
                  v-model="v.category"
                  clearable
                  placeholder="选择卡类别"
                >
                  <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开始日期" prop="startDate">
                <el-date-picker
                  class="inlineFormInput"
                  v-model="v.startDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结束日期" prop="endDate">
                <el-date-picker
                  class="inlineFormInput"
                  v-model="v.endDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="form-delete-btn">
              <el-button
                type="danger"
                icon="el-icon-remove-outline"
                plain
                circle
                @click="deleteFormItem(i)"
              ></el-button>
            </div>
            <div class="form-index-btn">
              <div class="index-item">{{ i + 1 }}</div>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="dialogOpenCardVisible = false">取消</el-button>
        <el-button @click="saveOpenCard" type="primary">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
import statusManage from "./utils/status";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        paperType: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        paperNumber: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入人员编号", trigger: "blur" }],
        deptId: [{ required: true, message: "请选择部门", trigger: "change" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
        photo: [{ required: true, message: "请选择图片", trigger: "change" }],
      },
      openCardRules: {
        personId: [
          { required: true, message: "请输入人员ID", trigger: "blur" },
        ],
        personName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        cardNumber: [
          { required: true, message: "请输入卡号", trigger: "blur" },
        ],
        cardType: [
          { required: true, message: "请选择卡类型", trigger: "change" },
        ],
        category: [
          { required: true, message: "请选择卡类别", trigger: "change" },
        ],
        startDate: [
          { required: true, message: "请选择开始日期", trigger: "change" },
        ],
        endDate: [
          { required: true, message: "请选择结束日期", trigger: "change" },
        ],
      },
      loading: false,
      dialogVisible: false,
      dialogFaceVisible: false,
      dialogOpenCardVisible: false,
      personnelList: [],
      departmentList: [],
      paperTypeList: [],
      // 0/IC卡;1/有源RFIID;2/CPU卡
      categoryList: [
        {
          id: "0",
          label: "IC卡",
        },
        {
          id: "1",
          label: "有源RFIID",
        },
        {
          id: "2",
          label: "CPU卡",
        },
      ],
      cardTypeList: [
        {
          id: "0",
          label: "普通卡",
        },
        {
          id: "1",
          label: "VIP卡",
        },
        {
          id: "2",
          label: "来宾卡",
        },
        {
          id: "3",
          label: "巡逻卡",
        },
        {
          id: "6",
          label: "巡检卡",
        },
        {
          id: "11",
          label: "管理员卡",
        },
      ], //0/普通卡;1/VIP卡;2/来宾卡;3/巡逻卡;6/巡检卡;11/管理员卡
      statusManage,
      formLabelWidth: "100px",
      headers: {
        token: getToken(),
      },
      openCardForm: [
        {
          personId: "",
          personName: "",
          cardNumber: "",
          cardType: "",
          category: "",
          startDate: "",
          endDate: "",
        },
      ],
      faceAuth: {
        cardNumbers: [],
        timeQuantumId: "",
        cardPrivilegeDetails: [
          {
            privilegeType: "",
            resouceCode: "",
          },
        ],
      },
      statusList: [
        //在职、离职
        { value: "在职", label: "在职" },
        { value: "离职", label: "离职" },
      ],

      searchQuery: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      filtterData: {
        name: "",
        personIdentityId: "",
        phone: "",
        cardNumber: "",
        code: "",
        deptIdsString: "",
        status: "",
      },
      form: {
        paperType: "",
        paperNumber: "",
        name: "",
        code: "",
        deptId: "",
        sex: "",
        birthday: "",
        phone: "",
        status: "",
        fingerCode: "",
        secfingerCode: "",
        personIdentityId: "-99",
        photo: "",
      },

      props: [
        {
          label: "姓名",
          prop: "name",
          align: "center",
        },

        {
          label: "性别",
          prop: "sex",
          align: "center",
        },
        {
          label: "人员编号",
          prop: "code",
          align: "center",
        },
        {
          label: "部门",
          prop: "deptName",
          align: "center",
        },
        {
          label: "身份",
          prop: "personIdentity",
          align: "center",
        },
      ],
      selecTionArr: [],
      deviceList: [],
      openPlanList: [],
    };
  },
  created() {
    this.getDepartmentList();
    this.getPaperTypeList();
    this.getList();
    this.getDeviceList();
    this.getOpenPlanList();
  },
  watch: {
    filtterData: {
      handler() {
        this.getList();
      },
      // immediate: true,
      deep: true, //开启深度监听
    },
  },
  mounted() {},

  methods: {
    selectMultiple(e) {
      console.log("e", e);
    },
    // 删除
    async handelDel() {
      if (this.selecTionArr.length > 0) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let idArr = this.selecTionArr.map((x) => {
              return x.id;
            });
            console.log("idArr", idArr);
            let res = await api.common.commonDhPost({
              apiName: "/dh/personnelMgt/delete",
              data: { ids: idArr },
            });
            console.log("res", res);
            if (res.data.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message({
          type: "error",
          message: "请选择需要删除的人员！",
        });
      }
    },
    // 提交开卡
    async saveOpenCard() {
      let { openCardForm } = this;
      console.log("openCardForm", openCardForm);
      let result = await api.common.commonDhPost({
        apiName: "/cardMgt/open",
        data: openCardForm,
      });
      this.dialogOpenCardVisible = false;
      if (result.code == 200) {
        if (result.data.success) {
          this.$message({
            type: "success",
            message: "开卡成功",
          });
        } else {
          this.$message({
            type: "error",
            message: result.data.errMsg,
          });
        }
      }
    },
    // 删除表单数据
    deleteFormItem(index) {
      this.openCardForm.splice(index, 1);
    },
    // 开卡赋值用户到表单
    openCard() {
      this.dialogOpenCardVisible = true;
      this.openCardForm = this.selecTionArr.map((x) => {
        return {
          personId: x.id,
          personName: x.name,
          cardNumber: "",
          cardType: "",
          category: "",
          startDate: this.$publicfuc.formatDate(new Date(), 4),
          endDate: this.$publicfuc.formatDate(
            new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            4
          ),
        };
      });
    },
    //修改
    handelEdit() {
      let editForm = this.selecTionArr[0];
      let { form } = this;
      const obj = Object.assign(form, editForm);
      console.log("form", obj);
    },
    // table选中回调函数
    getSelection(e) {
      console.log(e);
      this.selecTionArr = e;
    },
    // 分页
    onSizeChange(e) {
      this.searchQuery.pageNum = e;
      this.getList();
    },
    // 分页
    onPageChange(e) {
      this.searchQuery.pageNum = e;
      this.getList();
    },
    // 获取部门管理
    async getDepartmentList() {
      let res = await api.face.getDepartmentList();
      if (res.code == 200) {
        this.departmentList = res.data.data;
      }
    },
    // 获取证件类型列表
    async getPaperTypeList() {
      let res = await api.common.commonDhGet({
        apiName: "/systemDataDictionary/getDictionaryParam",
        data: { type: "人员-证件类型" },
      });
      if (res.code == 200) {
        this.paperTypeList = res.data.map((x) => {
          return {
            id: x.id,
            name: x.parameterName,
          };
        });
      }
    },
    async getDeviceList() {
      try {
        let data = {
          pageNum: 1,
          pageSize: 30,
        };
        let result = await api.concierge.getList(data);

        if (result.code == 200) {
          // 判断code是否等于200
          if (result.data.code == 403) {
            // 如果data.coded 等于403 说明大华平台登录过期需要重新登录
            let dhLogin = await api.common.dhLogin();

            if (dhLogin.code == 200) {
              // 大华平台登录成功 后重新获取设备列表
              this.getDeviceList();
            }
          } else {
            // 过滤第一条数据不要
            this.deviceList = result.data.data.pageData.filter((x) => {
              return x.deviceCode != 1000023;
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 保存提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async save() {
      let { form } = this;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          //
          // 移除对象空值
          let data = this.$utils.filterNull(form);
          console.log("data", data);
          let result = await api.common.commonDhPost({
            apiName: "/dh/personnelMgt/create",
            data,
          });
          console.log("submit!", result);
          this.dialogVisible = false;
          this.getList();
          if (result.code == 200) {
            this.$message({
              type: "success",
              message: result.message,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除头像回调 清空头像值
    onRemove() {
      this.form.photo = "";
    },
    // 选择图片后转成Base64流
    getFile(file) {
      this.$utils.getBase64(file.raw).then((res) => {
        this.form.photo = res;
      });
    },
    // 获取人员列表i
    async getList() {
      this.loading = true;
      let { $test, filtterData, searchQuery } = this;
      let data = {
        pageNum: searchQuery.pageNum,
        pageSize: searchQuery.pageSize,
      };

      // 获取列表时为保证传多余的参数 需要判断值是否为空  空值不传字段，非空再传
      for (const key in filtterData) {
        if (!$test.isEmpty(filtterData[key])) {
          data[key] = filtterData[key];
        }
      }
      //
      let res = await api.common.daList({
        apiName: "/dh/personnelMgt",
        data: data,
      });
      this.loading = false;
      this.personnelList = res.data.data.pageData;
      this.searchQuery.total = res.data.data.totalRows;
    },
    // 获取开门计划列表i
    async getOpenPlanList() {
      let data = {
        pageNum: 1,
        pageSize: 22,
      };

      //
      let res = await api.common.daList({
        apiName: "/dh/openPlan",
        data: data,
      });
      this.openPlanList = res.data.data.pageData;
    },
  },
};
</script>

<style lang="scss" scoped>
.inlineForm {
  /deep/ .el-form-item:not(odd) {
    margin-right: 50px;
    margin-bottom: 0;
  }
}
.inlineFormInput {
  width: 180px;
}
.fullWidth {
  width: 100% !important;
  overflow: hidden;
}
.avatar-uploader {
  // border: 1px dashed #d9d9d9;
  /deep/ .el-upload--picture-card {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 80px;
    height: 80px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.form-box {
  height: 500px;
  overflow: auto;
  .form-item-box {
    border-bottom: 1px #ccc solid;
    position: relative;
    .form-delete-btn {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .form-index-btn {
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      .index-item {
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: rgba(103, 194, 58, 0.6);
        color: #fff;
        border-radius: 50%;
        text-align: center;
        font-style: italic;
        font-size: 16px;
      }
    }
  }
}
</style>
